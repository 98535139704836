import React, { useRef } from "react";
import { getDetailContract } from "../../API/ApiContratos";
import { AuthContext } from "../../auth/authContext";
import { BuscadorDeContratosParaReporteComponent } from "../../Components/BuscadorDeContratosParaReporteComponent/BuscadorDeContratosParaReporteComponent";
import { LoadingComponent } from "../../Components/LoadingComponent/LoadingComponent";
import imagenVita from "../../Images/logoVitanova.jpg";

import { useReactToPrint } from "react-to-print";
import CambiarFormatoFechaHelper from "../../Helpers/CambiarFormatoFechaHelper";
import { BuscadorDeContratosParaReporteComponentEstadoDeCuenta } from "../../Components/BuscadorDeContratosParaReporteComponent/BuscadorDeContratosParaReporteComponentEstadoDeCuenta";

export const EstadoDeCuentaContratosScreenComponent = () => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { user, dispatch } = React.useContext(AuthContext);

  const [datosdeContrato, setDatosDeContrato] = React.useState({
    etapaUno: [],
    etapaDos: [],
  });

  const [numeroContratoEncontrado, setNumeroContratoEncontrado] =
    React.useState(0);

  const [finalData, setFinalData] = React.useState({
    etapaUno: [],
    etapaDos: [],
    espacios: [],
    comisiones: [],
    pagos: [],
  });

  const [totalPagosFinal, setTotalPagosFinal] = React.useState(0);

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let listo = false;
    setLoading(true);
    let TotalPagos = 0;

    if (numeroContratoEncontrado == 0) {
      setLoading(false);
      return;
    }
    getDetailContract(user, numeroContratoEncontrado).then((resultado) => {
      if (resultado.status == 200) {
        
        setLoading(false);
        let finalValues = [];
        for (const contrato of resultado.data.pagos) {
          if (contrato.monto != "0.00") {
            finalValues.push(contrato);
            TotalPagos = TotalPagos + parseFloat(contrato.monto);
          }
        }

        resultado.data.pagos = finalValues;

        setFinalData(resultado.data);
        setTotalPagosFinal(TotalPagos);
      }
      setLoading(false);
    });

    return () => {
      listo = true;
    };
  }, [numeroContratoEncontrado]);

  return (
    <>
      {loading == true ? (
        <LoadingComponent />
      ) : (
        <>
          <div className="container-fluid d-print-none" ref={componentRef}>
            <div className="row">
              <div className="col-12 mt-3">
                <BuscadorDeContratosParaReporteComponentEstadoDeCuenta
                  numeroContratoEncontrado={numeroContratoEncontrado}
                  setNumeroContratoEncontrado={setNumeroContratoEncontrado}
                  datosdeContrato={datosdeContrato}
                  setDatosDeContrato={setDatosDeContrato}
                />
              </div>
            </div>
          </div>
          <div className="container-fluid d-print-none">
            <div className="row text-end">
              <div className="col-sm-12 mt-5">
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => {
                    window.print();
                  }}
                >
                  Imprimir
                </button>
              </div>
            </div>
          </div>
          <div className="container-fluid d-print-grid">
            <div className="row">
              {/* Encabezado */}
              <div className="col-12 mt-3">
                <div className="container-fluid d-print-grid">
                  <div className="row">
                    <div className="col-sm-2 d-print-grid">
                      <img
                        alt="logoVitanova"
                        className="mx-auto"
                        src={imagenVita}
                        width={100}
                      />
                    </div>
                    <div className="col-sm-10 text-end small">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-6">
                            {finalData.etapaUno.map((etapaUno, index) => (
                              <React.Fragment key={index}>
                                <strong>Contrato:</strong> {etapaUno.serie} -{" "}
                                {etapaUno.noContrato}
                                <br />
                                <strong>Fecha:</strong>{" "}
                                {CambiarFormatoFechaHelper(
                                  etapaUno.fechaContrato
                                )}
                                <br />
                                <strong>Status:</strong>{" "}
                                {etapaUno.status === 1 ? <>Activo</> : null}
                                {etapaUno.status === 2 ? <>Cancelado</> : null}
                                {etapaUno.status === 3 ? <>Inactivo</> : null}
                                {etapaUno.status === 4 ? <>Otorgado</> : null}
                                {etapaUno.status === 5 ? <>Pagado</> : null}
                                {etapaUno.status === 6 ? (
                                  <>Restructurado</>
                                ) : null}
                                <br />
                                <strong>Nombre:</strong> {etapaUno.titular}
                                <br />
                                <strong>
                                  Domicilio: {etapaUno.domicilio}
                                </strong>
                                <br />
                                <strong>Serie:</strong> {etapaUno.serie}
                                <br />
                              </React.Fragment>
                            ))}
                          </div>
                          <div className="col-6">
                            {finalData.etapaDos.map((etapaDos, index) => (
                              <React.Fragment key={index}>
                                <strong>Importe: </strong>{" "}
                                {parseFloat(etapaDos.importe)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                <br />
                                <strong>Saldo: </strong>{" "}
                                {parseFloat(etapaDos.saldo)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                <br />
                                <strong>Plazo: </strong>{" "}
                                {parseFloat(etapaDos.plazo)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                <br />
                                <strong>Serie de Cobranza: </strong>{" "}
                                {etapaDos.serieDeCobranza}
                                <br />
                              </React.Fragment>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Pagos */}
              <div className="col-12">
                <table className="table text-center">
                  <thead>
                    <tr>
                      <th scope="col">No. Pago</th>
                      <th scope="col">Fecha Pago</th>
                      <th scope="col">Folio / Número Folio</th>
                      <th scope="col">Monto</th>
                      <th scope="col">Saldo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        <strong>Total: </strong>
                      </th>
                      <td></td>
                      <td></td>
                      <td>
                        {" "}
                        {"$ "}
                        {parseFloat(totalPagosFinal)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </td>
                      <td></td>
                    </tr>
                    {finalData.pagos.map((pagos, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{CambiarFormatoFechaHelper(pagos.fechaPago)}</td>
                          <td>
                            {pagos.serie} - {pagos.folio}
                          </td>
                          <td>
                            {"$ "}
                            {parseFloat(pagos.monto)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </td>
                          <td>
                            {"$ "}
                            {parseFloat(pagos.saldo)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                    <tr>
                      <th>
                        <strong>Total: </strong>
                      </th>
                      <td></td>
                      <td></td>

                      <td>
                        {" "}
                        {"$ "}
                        {parseFloat(totalPagosFinal)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
