import React from "react";
import imagen from "../../Images/LogotipoNegro.png";
import styles from "./InicioScreenComponent.module.css";
import { Chip } from "@mui/material";
import { DateTime } from "luxon";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import imagen1 from "../../Images/accesoprincipal/1.png";
import imagen2 from "../../Images/accesoprincipal/2.png";
import imagen3 from "../../Images/accesoprincipal/3.png";
import imagen4 from "../../Images/accesoprincipal/4.png";
import imagen5 from "../../Images/accesoprincipal/5.png";
import imagen6 from "../../Images/accesoprincipal/6.png";
import imagen7 from "../../Images/accesoprincipal/7.png";
import imagen8 from "../../Images/accesoprincipal/8.png";
import { AuthContext } from "../../auth/authContext";
import { checkPermisosMenuPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";

let aplicaciones = [
  {
    id: 1,
    nombre: "Cobranza",
    urlImagen: imagen7,
    urlDevelopment: "https://cobranzavita.wiboo.mx:4001",
    urlStaging: "https://devcobranza.funerariavitanova.com",
    urlProduccion: "https://cobranza.funerariavitanova.com",
    guidVista: "8b6a68b4-37f4-49c2-8a87-4e26dd8541a8",
  },

  {
    id: 1,
    nombre: "VPS",
    urlImagen: imagen8,
    urlDevelopment: "https://devvps.funerariavitanova.com",
    urlStaging: "https://devvps.funerariavitanova.com",
    urlProduccion: "https://vps.funerariavitanova.com",
    guidVista: "cbccc653-1e1c-4b8c-9ba1-67985c23d598",
  },
];

let accionesRapidas = [
  {
    id: 1,
    nombre: "Ver Contratos",
    descripción: "",
    urlacceso: "/contratos",
    urlImagen: imagen1,
    guidVista: "6ac593c2-4810-4081-bb29-e80830750a85",
  },
  {
    id: 2,
    nombre: "Ver Órdenes",
    descripción: "",
    urlacceso: "/ordenes",
    urlImagen: imagen2,
    guidVista: "d5376ba2-cefd-4edd-8402-362d5bd4c75d",
  },

  {
    id: 3,
    nombre: "E. Cuenta Contratos",
    descripción: "",
    urlacceso: "/estadodecuentacontratos",
    urlImagen: imagen3,
  },
  {
    id: 45753,
    nombre: "E. Cuenta Contratos 2",
    descripción: "",
    urlacceso: "/estadodecuentacontratosdos",
    urlImagen: imagen3,
  },
  {
    id: 4,
    nombre: "E. Cuenta Órdenes",
    descripción: "",
    urlacceso: "/estadodecuentaordenesservicio",
    urlImagen: imagen4,
  },

  {
    id: 5,
    nombre: "Generar Reportes",
    descripción: "",
    urlacceso: "/reportes",
    urlImagen: imagen5,
    guidVista: "ef433e95-e3b0-4caa-a533-3b599add112e",
  },
  {
    id: 6,
    nombre: "Ingresar Pagos",
    descripción: "",
    urlacceso: "/ingresarpagos",
    urlImagen: imagen6,
  },
];

export const InicioScreenComponent = () => {
  //Buscar los permisos.

  const { user, dispatch } = React.useContext(AuthContext);
  const [permisos, setPermisos] = React.useState([]);

  React.useEffect(() => {
    checkPermisosMenuPorUsuarioPorGuidPantalla(user).then((resultado) => {
      if (resultado.data === undefined) {
        return;
      }
      setPermisos(resultado.data);
    });
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h5>Te damos la bienvenida al Sistema de Funeraria Vitanova</h5>
            <Chip label="Funeraria Vitanova" size="small" color="success" />
            <span className="ms-3">
              <CalendarMonthIcon fontSize="inherit" /> Hoy es{" "}
              {DateTime.now().toLocaleString()}
            </span>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
            <h6>Acciones rápidas</h6>
          </div>
        </div>
      </div>
      <div className="container mt-2">
        <div className="row">
          {accionesRapidas.map((accion) => {
            //Buscar primero si tiene permisos.

            let permisoAcceso = permisos.find(
              (x) => x.guidVista === accion.guidVista
            );

            if (permisoAcceso?.allowed === 0) {
              return null;
            } else if (permisoAcceso === undefined) {
              return (
                <React.Fragment key={accion.id}>
                  <div className="col-md-2 text-center cursor-pointer">
                    <div
                      className=""
                      onClick={() => {
                        window.open(accion.urlacceso, "_self");
                      }}
                    >
                      <img
                        src={accion.urlImagen}
                        alt={accion.nombre}
                        style={{ width: "100%" }}
                      />
                      <strong>{accion.nombre}</strong>
                    </div>
                  </div>
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment key={accion.id}>
                  <div className="col-md-2 text-center cursor-pointer">
                    <div
                      className=""
                      onClick={() => {
                        window.open(accion.urlacceso, "_self");
                      }}
                    >
                      <img
                        src={accion.urlImagen}
                        alt={accion.nombre}
                        style={{ width: "100%" }}
                      />
                      <strong>{accion.nombre}</strong>
                    </div>
                  </div>
                </React.Fragment>
              );
            }
          })}
        </div>
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
            <h6>Nuevos Módulos</h6>
          </div>
        </div>
      </div>
      <div className="container mt-2">
        <div className="row">
          {aplicaciones.map((accion) => {
            let permisoAcceso = permisos.find(
              (x) => x.guidVista === accion.guidVista
            );

            let urlFinal = "";
            if (process.env.REACT_APP_CONNECTION_MODE === "Development") {
              urlFinal = accion.urlDevelopment;
            }
            if (process.env.REACT_APP_CONNECTION_MODE === "Staging") {
              urlFinal = accion.urlStaging;
            }
            if (process.env.REACT_APP_CONNECTION_MODE === "Production") {
              urlFinal = accion.urlProduccion;
            }

            if (permisoAcceso?.allowed === 1) {
              return (
                <React.Fragment key={accion.id}>
                  <div className="col-md-2 text-center cursor-pointer">
                    <div
                      className=""
                      onClick={() => {
                        window.open(urlFinal, "_self");
                      }}
                    >
                      <img
                        src={accion.urlImagen}
                        alt={accion.nombre}
                        style={{ width: "100%" }}
                      />
                      <strong>{accion.nombre}</strong>
                    </div>
                  </div>
                </React.Fragment>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </>
  );
};
