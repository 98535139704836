import { createContext, useState } from "react";

// 1. Crear el contexto
export const VendedoresContratoContext = createContext();

// 2. Crear el proveedor del contexto
export const VendedoresContratoProvider = ({ children }) => {
  const [dataVendedoresContext, setDataVendedoresContext] = useState({});

  return (
    <VendedoresContratoContext.Provider
      value={{ dataVendedoresContext, setDataVendedoresContext }}
    >
      {children}
    </VendedoresContratoContext.Provider>
  );
};
