import { API, APIImpresoraTickets } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";

export const solicitarTicketParaImpresion = async (user, values) => {
  let url = APIImpresoraTickets + "api/ticket";

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        maxBodyLength: Infinity,
      },
      responseType: "blob",
    });

    if (response.status === 200) {
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(pdfBlob);

      const printWindow = window.open(blobUrl);
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.focus();
          printWindow.print();
          // Eliminar la llamada a printWindow.close() para que la ventana no se cierre automáticamente
          URL.revokeObjectURL(blobUrl); // Liberar memoria
        };
      } else {
        console.error("No se pudo abrir la ventana de impresión.");
      }

      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};


export const solicitarTicketParaImpresionOrdenServicio = async (user, values) => {
  let url = APIImpresoraTickets + "api/ticket/ordendeservicio";

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        maxBodyLength: Infinity,
      },
      responseType: "blob",
    });

    if (response.status === 200) {
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(pdfBlob);

      const printWindow = window.open(blobUrl);
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.focus();
          printWindow.print();
          // Eliminar la llamada a printWindow.close() para que la ventana no se cierre automáticamente
          URL.revokeObjectURL(blobUrl); // Liberar memoria
        };
      } else {
        console.error("No se pudo abrir la ventana de impresión.");
      }

      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};
