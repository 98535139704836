import React from "react";
import styles from "./UltimosPagosRegistradosComponent.module.css";
import Button from "@mui/material/Button";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";

import jwt_decode from "jwt-decode";
import { AuthContext } from "./../../auth/authContext";
import { ActualizadorContext } from "./../../actualizadorContext/actualizadorContext";

import { DateTime } from "luxon";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  addPrecioMantenimientoAPI,
  obtenerTicketsParaReimpresionAPI,
} from "../../API/ApiPagosRegistrados";

import style from "./UltimosPagosRegistradosComponent.module.css";
import { reimprimirTicket } from "../../API/ApiImpresoraTickets";

import Skeleton from "@mui/material/Skeleton";
import CambiarFormatoFechaHelper from "../../Helpers/CambiarFormatoFechaHelper";
import { solicitarTicketParaImpresion } from "../../API/APIImpresionTickets";
import { toast } from "react-toastify";
import convertToPesosMexicanos from "../../Helpers/ConvertToPesosMexicanos";
import { useLoading } from "../../contexts/LoadingContext";

const UltimosPagosRegistradosComponent = () => {
  const { isLoading, startLoading, stopLoading } = useLoading();

  const { user, dispatch } = React.useContext(AuthContext);

  const { actualizarVentanaContext, dispatchActualizarVentanaContext } =
    React.useContext(ActualizadorContext);

  const [isDisabled, setIsDisabled] = React.useState(false);

  const [finalData, setFinalData] = React.useState([]);

  React.useEffect(() => {
    //Primero necesito obtener la fecha del día de hoy para el corte
    const now = DateTime.now().toISODate();
    var { id } = jwt_decode(user.accessToken);

    startLoading();

    obtenerTicketsParaReimpresionAPI(user, now, now, id).then((resultado) => {
      stopLoading();
      if (resultado.status === 200) {
        console.log(resultado.data);
        setFinalData(resultado.data);
      } else {
        setFinalData({
          pagosOrdenes: [],
          pagosPrevision: [],
          arregloUnificado: [],
        });
      }
    });
  }, [actualizarVentanaContext]);

  return (
    <div className={`container-fluid ${style.contenedorUltimosPagos}`}>
      <div className="row">
        <div className="col-12">
          <h6>
            Útimos Pagos Registrados <br />{" "}
            <small>
              {" "}
              <CalendarTodayIcon fontSize="small" />{" "}
              {CambiarFormatoFechaHelper(DateTime.now().toISODate())}
            </small>
          </h6>
          <br />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-2">
          {isLoading ? (
            <>
              <Skeleton
                variant="rectangular"
                width="100%"
                style={{ marginBottom: "10px" }}
              >
                <div style={{ paddingTop: "30%" }} />
              </Skeleton>
              <Skeleton
                variant="rectangular"
                width="100%"
                style={{ marginBottom: "10px" }}
              >
                <div style={{ paddingTop: "30%" }} />
              </Skeleton>
              <Skeleton
                variant="rectangular"
                width="100%"
                style={{ marginBottom: "10px" }}
              >
                <div style={{ paddingTop: "30%" }} />
              </Skeleton>
              <Skeleton
                variant="rectangular"
                width="100%"
                style={{ marginBottom: "10px" }}
              >
                <div style={{ paddingTop: "30%" }} />
              </Skeleton>
              <Skeleton
                variant="rectangular"
                width="100%"
                style={{ marginBottom: "10px" }}
              >
                <div style={{ paddingTop: "30%" }} />
              </Skeleton>
            </>
          ) : (
            <>
              {finalData.map((pago) => {
                debugger;
                return (
                  <React.Fragment key={pago.idpago}>
                    <div className="card mb-2">
                      <div className={`${styles.cardPago} card-body`}>
                        <div className="d-flex justify-content-between">
                          <h6>{pago.folio}</h6>
                        </div>
                        <div className="d-flex justify-content-between">
                          <h6>Fecha</h6>
                          <h6>
                            {DateTime.fromISO(pago.fechaPago).toFormat(
                              "dd/MM/yyyy"
                            )}
                          </h6>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between strong d-none">
                          <h6>Método</h6>
                          <h6>
                            {pago.idtipoPago == 1
                              ? "Efectivo"
                              : pago.idtipoPago == 2
                              ? "Tarjeta"
                              : "No Identificado  "}
                          </h6>
                        </div>
                        <div className="d-flex justify-content-between strong">
                          <h6>Total:</h6>
                          <h6>{convertToPesosMexicanos(pago.abono)}</h6>
                        </div>
                        <div className="d-flex justify-content-center strong">
                          <Button
                            variant="text"
                            onClick={() => {
                              let armandoObjeto = {
                                encabezado: {
                                  urlImagen:
                                    "https://login.funerariavitanova.com/logo.jpg",
                                  telefono: "4616120088",
                                  correo: "cobranza@vitanova.com",
                                  paginaWeb: "www.funerariavitanova.com",
                                },
                                detalle: {
                                  nombreTitular: pago.nombreTitular,
                                  fechaPago: pago.fechaPago,
                                  folio: pago.folio,
                                  contrato: pago.contrato,
                                  saldoAnterior: pago.saldoAnterior,
                                  abono: pago.abono,
                                  saldoActual: pago.saldoActual,
                                  uuid: pago.uuid,
                                },
                              };
                              solicitarTicketParaImpresion(
                                user,
                                armandoObjeto
                              ).then((resultado) => {
                                toast.success("Imprimiendo Ticket...");
                              });
                            }}
                          >
                            <LocalPrintshopIcon fontSize="small" /> Reimpresión
                          </Button>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UltimosPagosRegistradosComponent;
