import React, { useContext } from "react";
import { getDetalleComisionesContratoAPI } from "../../API/APiVendedores";
import { AuthContext } from "../../auth/authContext";
import { toast } from "react-toastify";
import { FormatCurrency } from "../../Helpers/FormatCurrency";
import { convertToMexicanDate } from "../../Helpers/convertToMexicanDate";
import { PiMathOperationsFill } from "react-icons/pi";
import { CgMathPlus } from "react-icons/cg";
import { AddVendedorToContratoComponent } from "../AddVendedorToContratoComponent/AddVendedorToContratoComponent";
import { IoIosAlert } from "react-icons/io";
import { VendedoresContratoContext } from "../../contexts/VendedoresContratoContext";
const VendedoresDeUnContratoComponent = (props) => {
  const [finalDataVentas, setFinalDataVentas] = React.useState([]);
  const { user, dispatch } = React.useContext(AuthContext);

  const [crud, setCrud] = React.useState({ type: null, data: null });

  const { dataVendedoresContext, setDataVendedoresContext } = useContext(
    VendedoresContratoContext
  );

  React.useEffect(() => {
    getDetalleComisionesContratoAPI(user, props.contrato).then((resultado) => {
      if (resultado.status == 200) {
        setFinalDataVentas(resultado.data);

        setDataVendedoresContext(resultado.data);

        if (resultado.data.length == 0) {
          toast.info("No hay vendedores asignados a este contrato.");
        }
      } else {
        toast.error(
          "Ocurrió un error al mostrar los vendedores de un contrato."
        );
        setFinalDataVentas([]);
      }
    });
  }, [props.contadorActualizar]);

  return (
    <>
      {crud.type == "agregarVendedor" || crud.type == "calcularComisiones" ? (
        <>
          {" "}
          <AddVendedorToContratoComponent
            contrato={props.contrato}
            setCrudAction={setCrud}
            crudAction={crud}
            setContadorActualizar={props.setContadorActualizar}
            contadorActualizar={props.contadorActualizar}
          />{" "}
        </>
      ) : null}

      <div className="card mt-3">
        <div className="card-body">
          <div className="container-fluid mt-2">
            <div className="row">
              <div className="col-sm-10">
                <h6>Vendedores</h6>
                <div className="container-fluid">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Empleado</th>
                        <th className=" d-none">Contrato</th>
                        <th>Tipo Comision</th>
                        <th>Monto Comision</th>
                        <th>Status</th>
                        <th>Creado</th>
                      </tr>
                    </thead>
                    <tbody>
                      {finalDataVentas.map((vendedor, index) => (
                        <tr key={vendedor.id}>
                          <td>{index + 1}</td>
                          <td>{vendedor.cEmpleado.nombreCompleto || "N/A"}</td>
                          <td className=" d-none">{vendedor.idcontrato}</td>
                          <td>{vendedor.tipocomision.tipoComision}</td>
                          <td>{FormatCurrency(vendedor.montoComision)}</td>
                          <td>
                            {vendedor.status == 1
                              ? "Por liquidar"
                              : "Liquidado"}
                          </td>
                          <td>{convertToMexicanDate(vendedor.createdAt)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {finalDataVentas.length == 0 ? (
                    <div className="alert alert-danger">
                      <IoIosAlert className="me-2" /> No hay vendedores
                      asignados a este contrato.
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-sm-2 text-end">
                <h6>Acciones</h6>

                {dataVendedoresContext.length > 0 ? (
                  <>
                    <div
                      onClick={() => {
                        toast.info(
                          "No puedes agregar vendedores a un contrato que ya tiene vendedores asignados."
                        );
                      }}
                      class="alert alert-success text-center mt-2"
                      role="alert"
                    >
                      Vendedor agregado. ✅
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className=" w-100 btn btn-success mb-2"
                      onClick={() => {
                        setCrud({ type: "agregarVendedor", data: null });
                      }}
                    >
                      {" "}
                      <CgMathPlus /> Agregar Vendedor{" "}
                    </div>
                    <div
                      className=" w-100 btn btn-warning"
                      onClick={() => {
                        setCrud({ type: "calcularComisiones", data: null });
                      }}
                    >
                      {" "}
                      <PiMathOperationsFill /> Calcular Comisiones{" "}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendedoresDeUnContratoComponent;
